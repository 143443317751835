import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { FontAwesome5 } from "@expo/vector-icons";
import PrimaryButton from "./primary-button";

interface State {

}

interface Props {
  adiacentMessage?: string
  message: string
  onCancel?: any
  cancelLabel?: string
  onAccept?: any
  icon?: any
  acceptLabel: string | 'Ok'
  style?: ViewStyle
}

class Alert extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (
      <View style={[this.props.style, { padding: 10, width: '100%' }]}>
        <Text style={{ width: '100%', fontSize: 18, fontFamily: 'Jost' }}>{this.props.message}</Text>
        {this.props.cancelLabel ?
          <PrimaryButton isSecondary={true} align={'left'} onPress={() => {
            this.props.onCancel()
          }} label={this.props.cancelLabel} style={{
            width: '100%',
            marginTop: 20
          }} /> : null}
        <PrimaryButton icon={this.props.icon} adiacentMessage={this.props.adiacentMessage} onPress={() => {
          this.props.onAccept()
        }} label={this.props.acceptLabel} style={{
          width: '100%',
          marginTop: 20
        }} />
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default Alert;
